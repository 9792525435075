import React, { useEffect, useState, createRef, lazy, Suspense } from "react"
import { Helmet } from "react-helmet"
import LayoutTeam from "../components/layoutteam"
import Navigation from "../components/navigation"
import { Link } from "gatsby"

import "../styles/hybrid.scss"
import scrollTo from "gatsby-plugin-smoothscroll"
import SimpleSlider from "../components/slider"
import SEOHeader from "../components/seo-header"
import teamLogo from "../img/worqpass.svg"
import lottie from "lottie-web"
import VizSensor from "react-visibility-sensor"
import animation from "../animations/newhomeanimation.json"

import HybridReportImg from "../img/Report-Hybrid.webp"
import WorkFlexiAni from "../img/WorqFlexi.png"
import "lazysizes"
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect"
import Img from "gatsby-image"

import BlogCard from "../components/blog/blog-card"
import CaseStudies from "../components/case-studies"
import HomeSearchCitySelector from "../components/search/homesearchcityselector"
import ClientLogos from "../components/client_logo"
export default function Home(props) {
  const [viewportWidth, setViewportWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth)
    }

    // Initial width on component mount
    setViewportWidth(window.innerWidth)

    // Event listener for resize
    window.addEventListener("resize", handleResize)

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const homeBanner = props.data.allBanners.edges
  // Function to get a random element from an array
  function getRandomElement(array) {
    const randomIndex = Math.floor(Math.random() * array.length)
    return array[randomIndex]
  }

  // Get a random element from homeBanner
  const randomBanner = getRandomElement(homeBanner)

  const [imgViz, setimgViz] = useState(false)
  const [imgVizdisplay, setimgVizdisplay] = useState(false)
  const [teamBanner, setteamBanner] = useState(false)
  const [homeSearch, sethomeSearch] = useState(false)
  const [unlockDeals, setunlockDeals] = useState(false)
  const [testimonial, settestimonial] = useState(false)
  const [bookCoworking, setbookCoworking] = useState(false)
  const [menuItems, setmenuItems] = useState(false)
  const [clientLogoSection, setclientLogoSection] = useState(false)
  let animationContainer = createRef()
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation,
    })
    return () => {
      animationContainer.current = animationContainer.current || {}
      anim.destroy(animationContainer.current)
    } // optional clean up for unmounting
  }, [])
  const cityData = props.data.allCities.edges
  return (
    <div>
      <SEOHeader
        title="GoFloaters: Flexible Coworking Spaces & Meeting Rooms"
        description="Discover and book coworking spaces, shared offices, and meeting rooms effortlessly with GoFloaters. Choose from hourly, daily, or monthly bookings across 40+ cities and enjoy savings of up to 25%. Instant reservations available!"
        socialURL="https://assets.gofloaters.com/socialimage/homepage.jpg"
        pinterest="true"
      ></SEOHeader>
      <Helmet>
        <script type="application/ld+json">
          {`{
          "@context": "https://schema.org/",
          "@type": "Organization",
          "name": "GoFloaters",
          "url":"https://gofloaters.com/",
          "logo":"https://assets.gofloaters.com/icons/gofloaters_logo.png",
          "sameAs":["https://www.facebook.com/gofloatersindia", "https://www.instagram.com/gofloatersapp", "https://twitter.com/gofloaters", "https://www.youtube.com/channel/UCy_PdgFKsHuZY_hZ2r8gQvg","https://www.linkedin.com/company/gofloaters/"],
          "contactPoint":{
            "@type":"ContactPoint",
            "telephone":"+91 7338 7308 00",
            "contactType":"Customer Service"
          },
          "review": {
            "@type": "Review",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.6",
              "bestRating": "5"
            },
            "author": {
              "@type": "Person",
              "name": "Gofloaters App Users"
            }
          }
        }`}
        </script>
        <script type="application/ld+json">
          {` {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://www.gofloaters.com/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://www.gofloaters.com/sitesearch/?query={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    }`}
        </script>
      </Helmet>
      <LayoutTeam>
        <div id="gofloterstransparenthome">
          <div className="GofloaterHomeSearchImage">
            <Navigation whiteLogo="active" />
            <div className="container content">
              <div className="row">
                <div className="col-md-1"></div>

                <div className="col-md-10">
                  <h1 className=" text-center" style={{ color: "white" }}>
                    <i>Best</i> place to book meeting room or coworking space,
                    period.
                  </h1>
                  <HomeSearchCitySelector city={cityData} />{" "}
                </div>

                <div className="col-md-1"></div>
              </div>
            </div>

            <div className="clientList" style={{ minHeight: "90px" }}>
              <ClientLogos />
            </div>
          </div>

          {/* Home Banner End */}

          <div className="container-fluid" id="calltoaction">
            <div className="row  GofloaterHomeCoworking">
              <div className="col-md-12 text-center gofloaterHomenothing">
                <br></br>
                <h2>Our unique and affordable office solutions</h2>
                <br></br>
              </div>

              <div className="col-md-3">
                <div className="HomeCTACard">
                  <img
                    data-src="https://assets.gofloaters.com/homepage/new/meeting-rooms-by-the-hour.webp"
                    className="lazyload"
                    alt="Meeting rooms by the hour"
                  ></img>
                  <h5>Meeting rooms by the hour</h5>
                  <p>
                    Right sized room at the right price for any kind of meeting
                    from interviews to presentations.
                  </p>
                  <Link to="/meeting-spaces" className="searchWorkspace">
                    Check Options
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="HomeCTACard">
                  <img
                    data-src="https://assets.gofloaters.com/homepage/new/hybrid-workplace.webp"
                    className="lazyload"
                    alt="Hybrid Workplace"
                  ></img>
                  <h5>Hybrid Workplace</h5>
                  <p>
                    Your remote and distributed teams can now gain instant
                    access to 2000+ professional, productive coworking spaces
                    across 45 cities in India on a pay-per-use model.
                  </p>
                  <Link to="/worqflexi/" className="searchWorkspace">
                    Explore Solution
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="HomeCTACard">
                  <img
                    data-src="https://assets.gofloaters.com/homepage/new/virtual-offices.webp"
                    className="lazyload"
                    alt="Virtual Offices"
                  ></img>

                  <h5>Virtual Offices</h5>
                  <p>
                    Get an address to register your company or just as a
                    communication address. Service available across 24 cities at
                    rates starting Rs 1000 / month.
                  </p>
                  <Link to="/virtual-office/" className="searchWorkspace">
                    Check Pricing
                  </Link>
                </div>
              </div>
              <div className="col-md-3">
                <div className="HomeCTACard">
                  <img
                    data-src="https://assets.gofloaters.com/homepage/new/hot-desks-for-a-day.webp"
                    className="lazyload"
                    alt="Hot desks for a day"
                  ></img>
                  <h5>Hot desks for a day</h5>
                  <p>
                    Book a desk for a day at rates starting Rs 99/day. Work near
                    home for heads-down work.
                  </p>
                  <Link to="/coworking-spaces" className="searchWorkspace">
                    Check Options
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container" id="howwork">
          <div className="row teamPadding GofloaterHomeCoworking">
            <div className="col-md-6 DesktopOnly">
              <BrowserView>
                <Img
                  fluid={props.data.CoworkingBanner.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="Book a coworking space"
                ></Img>
              </BrowserView>
            </div>
            <div className="col-md-6">
              <h2>Book a coworking space</h2>
              <p>
                GoFloaters helps you find and book a coworking space near you
                for meetings and work.
              </p>
              <div className="points">
                <div className="row">
                  <div className="col-md-2 col-3 text-center">
                    <img
                      src="https://assets.gofloaters.com/teams/newhome/pay-per-use.svg"
                      alt="Pay per Use"
                      width="45"
                      height="44"
                    ></img>
                  </div>
                  <div className="col-md-10 col-9">
                    <h4>Pay-per-use</h4>
                    <p>
                      No contracts, or commitments.
                      <br></br>Book for an hour or a day.
                    </p>
                  </div>
                </div>
              </div>
              <div className="points">
                <div className="row">
                  <div className="col-md-2 col-3 text-center">
                    <img
                      src="https://assets.gofloaters.com/teams/newhome/plug-and-play.svg"
                      alt="plug and play"
                      width="45"
                      height="44"
                    ></img>
                  </div>
                  <div className="col-md-10 col-9">
                    <h4>Plug and play</h4>
                    <p>
                      Prices inclusive of all amenities
                      <br></br>
                      Prices starting ₹199/day
                    </p>
                  </div>
                </div>
              </div>
              <div className="points">
                <div className="row">
                  <div className="col-md-12">
                    <a
                      onClick={() => scrollTo("#hometopmoving")}
                      className=" searchWorkspace"
                    >
                      Search Workspaces
                    </a>
                    <br></br>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            background: "#F6F9FF",
          }}
          id="signup"
        >
          <div className="container">
            <div className="row teamPadding">
              <div className="col-md-12 text-center gofloaterHomenothing">
                <h2>Nothing can beat a face-to-face</h2>
                <p>Don’t take our word for it</p>
                <br></br>
              </div>
              <div className="col-md-4">
                <div className="HomeTestimonailCard">
                  <div className="hometextWrapper">
                    <h4>Freedom to set up my office</h4>
                    <p>
                      Gives me the freedom to set up my office across cities
                    </p>
                  </div>
                  <span className="MarkersHomeTestimonailCard"></span>
                  <div className="homelogoWrapper">
                    <img
                      data-src="https://assets.gofloaters.com/webp/vinay-004.webp"
                      alt="Vinay Pushpakaran"
                      className="lazyload"
                      width="75"
                      height="75"
                    ></img>

                    <p className="text-center">Vinay Pushpakaran</p>
                    <span>Future Impact Learning</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="HomeTestimonailCard">
                  <div className="hometextWrapper">
                    <h4>Plug and Play</h4>
                    <p>Never have to worry about Internet or Plug Points.</p>
                  </div>
                  <span className="MarkersHomeTestimonailCard"></span>
                  <div className="homelogoWrapper">
                    <img
                      data-src="https://assets.gofloaters.com/webp/arpita-002.webp"
                      className="lazyload"
                      alt="Arpitha Ramani"
                      width="75"
                      height="75"
                    ></img>

                    <p className="text-center">Arpitha Ramani </p>
                    <span>GE Healthcare</span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="HomeTestimonailCard">
                  <div className="hometextWrapper">
                    <h4>External meeting made easy</h4>
                    <p>My go-to platform for all our external meetings</p>
                  </div>
                  <span className="MarkersHomeTestimonailCard"></span>
                  <div className="homelogoWrapper">
                    <img
                      data-src="https://assets.gofloaters.com/webp/balesh-001.webp"
                      className="lazyload"
                      width="75"
                      height="75"
                      alt="Balesh Raghurajan"
                    ></img>

                    <p className="text-center">Balesh Raghurajan</p>
                    <span>Effilor Consulting Services</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <VizSensor
          partialVisibility
          onChange={isVisible => {
            setunlockDeals(isVisible)
            if (!unlockDeals) {
              setunlockDeals(isVisible)
            }
          }}
        >
          <>
            <div className="TeamHomeBanner">
              <div className="container">
                <div className="row teamPadding">
                  <div className="col-md-4 TeamHomeBannerleft">
                    <h2
                      style={{
                        fontWeight: "bold",
                        lineHeight: "1.3",
                      }}
                    >
                      Introducing<br></br>
                      WorqFlexi
                    </h2>
                    <br></br>
                    <img
                      data-src={teamLogo}
                      width="250"
                      height="63"
                      className="lazyload"
                      alt="Gofloater WorqFlexi"
                    ></img>
                    <br></br> <br></br>
                    <p>
                      Save more than 60% from your current office rental
                      contract by offering pay-per-use office space for your
                      remote team.
                    </p>
                    <Link to="/worqflexi/" className="searchWorkspace">
                      Show Me How
                    </Link>
                    <br></br>
                    <br></br>
                  </div>
                  <div className="col-md-6">
                    <div className="GoflaoterHomeRight">
                      <div className="row">
                        <div className="col-md-8">
                          <h4>SIGN UP</h4>
                          <p>
                            Sign up on our platform within 3 minutes. No credit
                            card required.
                          </p>
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        <div className="col-md-8">
                          <h4>CHOOSE WORKSPACES</h4>
                          <p>
                            Curate from 3000+ high quality spaces in 40+ cities
                            across India
                          </p>
                        </div>
                      </div>
                      <br></br>
                      <div className="row">
                        <div className="col-md-8">
                          <h4>PAY AS YOU GO</h4>
                          <p>
                            Set monthly budget. Pay as you go. Manage access.
                            Track utilization
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 introImage DesktopOnly">
                    <img data-src={WorkFlexiAni} className="lazyload" />
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <CaseStudies />
              </div>
            </div>
          </>
        </VizSensor>

        <VizSensor
          partialVisibility
          onChange={isVisible => {
            setimgViz(isVisible)
            if (!imgVizdisplay) {
              setimgVizdisplay(isVisible)
            }
          }}
        >
          <div className="container padding-60">
            <div className="row homepage">
              <div className="col-md-12 text-center">
                <h2 className="h1">Featured Blogs</h2>
              </div>
              <BlogCard></BlogCard>
            </div>
          </div>
        </VizSensor>
        <div className="container padding-60">
          <div className="row homepage">
            <div className="col-md-12 text-center">
              <h2 className="h1">Featured in</h2>
            </div>

            <div className="col-md-12">
              <SimpleSlider></SimpleSlider>
            </div>
          </div>
        </div>
        <div className="container padding-60">
          <div className="row homepage hydridFormDownload">
            <div className="col-md-5">
              <img
                data-src={HybridReportImg}
                className="lazyload img-responsive"
                alt="Unleashing the power of hybrid - remote with Insights from customers tractions"
              ></img>
            </div>
            <div className="col-md-7">
              <br></br>
              <h2
                style={{
                  fontWeight: "bold",
                  fontSize: "1.8em",
                  paddingBottom: "15px",
                }}
              >
                Unleashing the power of hybrid - remote with Insights from
                customers tractions
              </h2>
              <h3
                style={{
                  fontWeight: "400",
                  fontSize: "1.2em",
                }}
              >
                State of hybrid work - Trend report 2022 & 2023
              </h3>
              <br />
              <Link to="/hybrid-report" className="btn btn-default">
                Download Now
              </Link>
            </div>
          </div>
        </div>

        <div className="container padding-60">
          <div className="row homepage">
            <div className="col-md-12 text-center">
              <h2 className="h1">Our Partner Brands</h2>
            </div>
          </div>
          <br />
          <div className="row brandsLogo">
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/wework" className="brandBox">
                <Img
                  fluid={props.data.wework.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="WeWork"
                ></Img>
                <p>Explore {props.data.weworkcount.totalCount} spaces</p>
              </Link>
            </div>
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/cowrks" className="brandBox">
                <Img
                  fluid={props.data.cowrks.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="Cowrks"
                ></Img>
                <p>Explore {props.data.cowrkscount.totalCount} spaces</p>
              </Link>
            </div>
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/awfis" className="brandBox">
                <Img
                  fluid={props.data.awfis.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="AWFIS"
                ></Img>
                <p>Explore {props.data.awfiscount.totalCount} spaces</p>
              </Link>
            </div>
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/91springboard" className="brandBox">
                <Img
                  fluid={props.data.springboard.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="91Springboard"
                ></Img>
                <p>Explore {props.data.springboardcount.totalCount} spaces</p>
              </Link>
            </div>
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/innov8" className="brandBox">
                <Img
                  fluid={props.data.innov8.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="Innov8"
                ></Img>
                <p>Explore {props.data.innov8count.totalCount} spaces</p>
              </Link>
            </div>
            <div className="col-md-2 col-6 text-center">
              <Link to="/brand/mybranch" className="brandBox">
                <Img
                  fluid={props.data.mybranch.childImageSharp.fluid}
                  className="lazyload img-responsive"
                  alt="MyBranch"
                ></Img>
                <p>Explore {props.data.mybranchcount.totalCount} spaces</p>
              </Link>
            </div>
            <br /> <br />
          </div>
        </div>
      </LayoutTeam>
    </div>
  )
}

export const query = graphql`
  query HomeImagesNew {
    weworkcount: allListings(
      filter: { operatorName: { eq: "WeWork" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    cowrkscount: allListings(
      filter: { operatorName: { eq: "Cowrks" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    awfiscount: allListings(
      filter: { operatorName: { eq: "AWFIS" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    springboardcount: allListings(
      filter: { operatorName: { eq: "91Springboard" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    innov8count: allListings(
      filter: { operatorName: { eq: "Innov8" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    mybranchcount: allListings(
      filter: { operatorName: { eq: "MyBranch" }, slug: { ne: "" } }
      sort: { fields: isFeatured, order: DESC }
    ) {
      totalCount
    }
    springboard: file(relativePath: { eq: "brands/91springboard.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    awfis: file(relativePath: { eq: "brands/awfis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cowrks: file(relativePath: { eq: "brands/cowrks.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    innov8: file(relativePath: { eq: "brands/innov8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mybranch: file(relativePath: { eq: "brands/mybranch.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wework: file(relativePath: { eq: "brands/wework.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    kaleyra: file(relativePath: { eq: "teams/newhome/logos/kaleyra.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    typeform: file(relativePath: { eq: "teams/newhome/logos/typeform.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vinay: file(relativePath: { eq: "teams/newhome/vinay-004.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    arpita: file(relativePath: { eq: "teams/newhome/arpita-002.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    balesh: file(relativePath: { eq: "teams/newhome/balesh-001.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    CoworkingBanner: file(
      relativePath: { eq: "teams/newhome/coworking-spaces.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allBanners {
      edges {
        node {
          image
          spaceLocation
          spaceName
        }
      }
    }
    allCities {
      edges {
        node {
          city
          cityPriority
          displayCity
          slug
        }
      }
    }
  }
`
